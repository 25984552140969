






















import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";

@Component({
  components: {
    FormCard,
  },
})
export default class HistoryExport extends Mixins(RulesMixin, BaseForm) {
  @Prop({ type: Function, required: true })
  handleExport!: (filename: string) => Promise<any>;

  fileName = "";

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.fileName.length > 0) {
      event.preventDefault();
      this.handleExport(this.fileName);
    }
  }
}
