




















































import { Component, Mixins, Prop } from "vue-property-decorator";
import DataTable from "@/components/data-table/DataTable.vue";
import HistoryService from "@/services/HistoryService";
import { TableQuery } from "@/components/data-table/types";
import HistoryForm from "@/components/history-form/HistoryForm.vue";
import History, { HistoryChange } from "@/entity/History";
import Contact from "@/entity/Contact";
import DialogMixin from "@/mixins/DialogMixin";
import User from "@/entity/User";
import UserService from "@/services/UserService";
import CompareChangesDialog from "@/components/CompareChangesDialog.vue";
import Project from "@/entity/Project";
import Tag from "@/entity/Tag";
import TagService from "@/services/TagService";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import { getFirstQueryValueAsInt } from "@/utils/queryParams";
import ProjectHistoryDataTable from "@/components/data-tables/ProjectHistoryDataTable.vue";
import ContactHistoryDataTable from "@/components/data-tables/ContactHistoryDataTable.vue";
import HistoryExport from "@/components/history-export/HistoryExport.vue";
import { formatUnixTimestamp } from "@/utils/date";
import {
  tagIdsToNames,
  getProjectAuthorName,
  isHistoryEoi,
} from "@/entity/History";
import * as XLSX from "xlsx";

@Component({
  components: {
    HistoryExport,
    ContactHistoryDataTable,
    DataTable,
    HistoryForm,
    CompareChangesDialog,
    ProjectHistoryDataTable,
  },
})
export default class ProjectHistoryView extends Mixins(
  DialogMixin,
  TranslatedEnumListsMixin
) {
  @Prop({ required: true }) project!: Project;

  history: History[] = [];
  interestedOptions: Contact[] = [];
  authorOptions: User[] = [];
  tags: Tag[] = [];
  showSkeleton = true;
  isHistoryLoading = false;
  itemsLength = 0;
  options!: TableQuery;
  changesToCompare: HistoryChange[] = [];

  get historyId() {
    return getFirstQueryValueAsInt(this.$route.params.historyId);
  }

  async fetchHistory(): Promise<void> {
    try {
      this.isHistoryLoading = true;

      this.tags = (await TagService.find()).content;
      const options = Object.assign({}, this.options, {
        filterBy: {
          ...this.options.filterBy,
          projectId: this.project.id,
        },
      });

      const response = await HistoryService.find(options);
      this.history = response.content;
      this.itemsLength = response.totalItems;
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isHistoryLoading = false;
    }
  }

  async deleteHistory(historyId: number): Promise<void> {
    try {
      this.isHistoryLoading = true;
      const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

      if (didConfirm) {
        await HistoryService.delete(historyId);
        await this.fetchHistory();
      }
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isHistoryLoading = false;
    }
  }

  compareChanges(item: History): void {
    this.changesToCompare = JSON.parse(item.meta as string).change;
    this.openDialog("compareChanges");
  }

  onHistoryOpen(history: History) {
    this.$router.replace({
      name: "projectHistoryDetail",
      params: {
        historyId: (history.id as number).toString(),
      },
    });
    this.openDialog("historyForm", history.id);
  }

  onHistoryCancel() {
    this.$router.replace({
      name: "projectHistory",
    });
    this.closeDialog();
  }

  onHistoryFormSuccess() {
    this.$router.replace({
      name: "projectHistory",
    });
    this.closeDialog();
    this.fetchHistory();
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchHistory();
  }

  openExportDialog(): void {
    this.openDialog("export");
  }

  async handleExport(filename: string): Promise<any> {
    const finalData = this.history.map((history) => ({
      [this.$tc("date", 1)]: formatUnixTimestamp(history.historyDate),
      [this.$tc("interested", 1)]: `${history?.contact?.firstName ?? ""} ${
        history?.contact?.lastName ?? ""
      }`,
      [this.$tc("note", 1)]: history.note,
      [this.$tc("author", 1)]:
        getProjectAuthorName(history) ?? this.$tc("autoGenerated"),
      [this.$tc("tag", 2)]: tagIdsToNames(this.tags, history.tags).join(","),
      [this.$tc("type", 1)]: this.$t(`historyTypes.${history.eventType}`),
      [this.$tc("attachment", 1)]: this.$t(
        history.hasAttachments || isHistoryEoi(history) ? "yes" : "no"
      ),
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(workbook, worksheet, this.$tc("history", 1));

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }

  async created() {
    if (this.historyId) {
      this.openDialog("historyForm", this.historyId);
    }

    [this.authorOptions, this.interestedOptions] = await Promise.all([
      UserService.findAll(),
      HistoryService.contactNames({
        filterBy: {
          projectId: this.project.id,
        },
      }),
    ]);
    this.showSkeleton = false;
  }
}
